import axios from 'axios'

import {
  VESSEL_LIST,
  VESSEL_DETAIL,
  UPDATE_VESSEL,
  ADD_VESSEL,
  VESSEL_INFO, DELETE_VESSEL,
} from './ConstantService'
import { GlobalService } from './GlobalService'
import { UrlService } from '@/services/UrlService'

export const VesselService = {
  async getVesselList (params) {
    const queryString = UrlService.setQueryString(params)
    return await axios.post(VESSEL_LIST + '?page=' + params.page, {params}).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSELS LIST SERVICE')
      return false
    })
  },
  async getVesselDetail (params) {
    const url = VESSEL_DETAIL + `/${params}`
    return await axios.get(url).then(response => {
      // console.log(response.data)
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL DETAIL SERVICE')
      return false
    })
  },

  async getVesselInfo(params){
    return await axios.post(VESSEL_INFO, params)
      .then(response => {
        return response.data.vesselInfo ? response.data.vesselInfo : false;
      })
      .catch(error => {
        GlobalService.handleError(error, 'GET VESSEL INFO SERVICE');
        return false;
      });
  },

  async updateVessel (params) {
    return await axios.post(UPDATE_VESSEL, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE VESSEL SERVICE')
      return false
    })
  },
  async addVessel (params) {
    return await axios.post(ADD_VESSEL, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD VESSEL SERVICE')
      return false
    })
  },

  async deleteVessel(params){
    return await axios.post(DELETE_VESSEL,{vesselId:params}).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE VESSEL SERVICE')
      return false
    })
  }
}

export default { VesselService }
